<template>
  <div class="app" ref="refocus" tabindex="-1">
    <slot />
  </div>
</template>

<style lang="scss">
.app {
  &:focus {
    outline: 0;
  }
}
</style>